import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import useInView from '@propertypal/shared/src/hooks/useInView';
import { Property } from '@propertypal/shared/src/types/property';
import React, { FunctionComponent, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import useMediaQuery from '../media-query/useMediaQuery';
import PropertyBox from './PropertyBox';
import PropertyPlaceholder from './PropertyPlaceholder';
import {
  Container,
  Content,
  LoadingBox,
  Rail,
  Title,
  SubTitle,
  EmptyText,
  SliderButton,
} from './SimilarProperties.style';

interface Props {
  properties?: Property[];
  onInView?: () => void;
  title?: string;
  titleSize?: number;
  subTitle?: string;
  emptyText?: string;
  background?: string;
  imageLocator?: string;
  onFavourite?: () => (favourited: boolean, propertyId: number) => Promise<boolean>;
  hideOnEmpty?: boolean;
  wrapOnDesktop?: boolean;
  showArrows?: boolean;
  showFooter?: boolean;
  onClick?: () => void;
}

const SimilarProperties: FunctionComponent<Props> = (props) => {
  const container = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: `(${laptopMediumMax})` });
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const swiperEnabled = isMobile || !props.wrapOnDesktop; // swiper enabled prop only accepts initial prop
  useInView(container, { threshold: 0.5, rootMargin: '0px 0px 800px' }, props.onInView);

  if (props.hideOnEmpty && props.properties?.length === 0) {
    return null;
  }

  const title = () =>
    !!props.title && (
      <>
        <Title className="pp-similar-properties-title" $fontSize={props.titleSize}>
          {props.title}
        </Title>

        {!!props.subTitle && <SubTitle className="pp-similar-properties-sub-title">{props.subTitle}</SubTitle>}
      </>
    );

  if (!props.properties || (props.properties.length === 0 && props.emptyText)) {
    return (
      <Container className="pp-similar-properties" ref={container} background={props.background}>
        <LoadingBox>
          {title()}

          {!props.properties && (
            <Rail>
              <PropertyPlaceholder hideFooter={!props.showFooter} />
              <PropertyPlaceholder hideFooter={!props.showFooter} />
              <PropertyPlaceholder hideFooter={!props.showFooter} />
            </Rail>
          )}

          {props.properties?.length === 0 && props.emptyText && <EmptyText>{props.emptyText}</EmptyText>}
        </LoadingBox>
      </Container>
    );
  }

  if (props.properties && props.properties.length > 0) {
    return (
      <Container className="pp-similar-properties" data-testid="similarProperties" background={props.background}>
        <Content>{title()}</Content>

        <Content $wrapOnDesktop={props.wrapOnDesktop}>
          {!swiperEnabled && (
            <Rail>
              {props.properties.map((property) => (
                <PropertyBox
                  key={property.id}
                  property={property}
                  hideFooter={!props.showFooter}
                  imageLocator={props.imageLocator}
                  onFavourite={props.onFavourite}
                  onClick={props.onClick}
                />
              ))}
            </Rail>
          )}

          {props.showArrows && (
            <>
              {showLeftArrow && (
                <SliderButton $direction="left" onClick={() => swiper?.slidePrev()}>
                  <FontAwesomeIcon icon={faChevronLeft} size={24} />
                </SliderButton>
              )}

              {showRightArrow && (
                <SliderButton $direction="right" onClick={() => swiper?.slideNext()}>
                  <FontAwesomeIcon icon={faChevronRight} size={24} />
                </SliderButton>
              )}
            </>
          )}

          {swiperEnabled && (
            <Swiper
              onSwiper={setSwiper}
              simulateTouch
              slidesPerView="auto"
              onTransitionEnd={(mSwiper) => {
                setShowLeftArrow(!mSwiper.isBeginning);
                setShowRightArrow(!mSwiper.isEnd);
              }}
            >
              {props.properties.map((property) => (
                <SwiperSlide key={property.id}>
                  <PropertyBox
                    property={property}
                    hideFooter={!props.showFooter}
                    imageLocator={props.imageLocator}
                    onFavourite={props.onFavourite}
                    onClick={props.onClick}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Content>
      </Container>
    );
  }

  return null;
};

export default SimilarProperties;
